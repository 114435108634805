import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import CookieConsent from 'react-cookie-consent';
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader';
import SEO from './SEO';
import Header from './Header';
import Footer from './Footer';
import SubFooter from './SubFooter';
import '../scss/style.scss';
import { initIcons } from '../utils/icons';

initIcons();

deckDeckGoHighlightElement();

const Layout = (props) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      configJson {
        cookieConsent {
          text
          expiresIn
          cookieName
          buttonLabel
        }
      }
    }
  `);

  const { cookieConsent } = data.configJson;

  return (
    <>
      <SEO />
      <div className={`page${props.bodyClass ? ` ${props.bodyClass}` : ''}`}>
        <div id="wrapper" className="wrapper">
          <Header />
          <div className="pb-6">{props.children}</div>
        </div>
        <Footer />
        <SubFooter />
        <CookieConsent
          location="bottom"
          buttonText={cookieConsent.buttonLabel}
          cookieName={cookieConsent.cookieName}
          style={{
            background: '#f6f7ff',
            color: '#000',
            fontSize: '1rem',
          }}
          buttonStyle={{
            background: '#f15d3e',
            color: '#fff',
            fontSize: '1rem',
          }}
          expires={cookieConsent.expiresIn}
        >
          {cookieConsent.text}
        </CookieConsent>
      </div>
    </>
  );
};

export default Layout;
